/**
 * Custom Component - Floating Tile
 * @author Charles Harwood
 */
/**
 * Grid Variables - CH
 */
/**
 * Custom SASS Mixins - CH
 */
.floating-tile {
  position: relative;
}

.floating-tile .floating-tile__background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.floating-tile .container {
  padding-top: 12rem;
  padding-bottom: 8.5rem;
  overflow: visible;
  max-width: 1600px;
}

.floating-tile .container .row {
  max-width: none;
}

.floating-tile .floating-tile__item__heading {
  position: relative;
  margin-top: 0;
  margin-bottom: 0.9375em;
  text-transform: none;
}

.floating-tile .floating-tile__item__heading:before {
  display: block;
  position: absolute;
  bottom: calc(100% + 1.125em);
  left: 0;
  width: 1px;
  background-color: #FFFFFF;
  content: '';
}

.floating-tile .floating-tile__item .floating-tile__item__image {
  position: relative;
}

.floating-tile .floating-tile__item .floating-tile__item__image:before {
  content: '';
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  display: block;
  background: rgba(0, 0, 0, 0.3);
  height: 100%;
}

.floating-tile .floating-tile__item__text {
  margin-top: 0;
  margin-bottom: 36px;
}

.floating-tile .floating-tile__item__image {
  width: 100%;
  height: 0;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.floating-tile .floating-tile__item--alpha {
  padding: 0 2.5rem;
}

.floating-tile .floating-tile__item--alpha .floating-tile__item__image {
  padding-bottom: 142%;
}

.floating-tile .floating-tile__item--alpha .floating-tile__item__heading:before {
  height: 4.125em;
}

.floating-tile .floating-tile__item--bravo {
  padding: 20rem 0.7rem 0 6.5rem;
}

.floating-tile .floating-tile__item--bravo .floating-tile__item__image {
  margin-bottom: 10.5rem;
  margin-left: -2.5rem;
  padding-bottom: 51%;
}

.floating-tile .floating-tile__item--bravo .floating-tile__item__heading {
  max-width: 34rem;
}

.floating-tile .floating-tile__item--bravo .floating-tile__item__heading:before {
  height: 21.75em;
}

.floating-tile .floating-tile__item--bravo .floating-tile__item__text {
  max-width: 38rem;
}

.floating-tile .floating-tile__item--charlie {
  padding: 12.5rem 4.2rem 0 5rem;
}

.floating-tile .floating-tile__item--charlie .floating-tile__item__image {
  padding-bottom: 122%;
}

.floating-tile .floating-tile__item--charlie .floating-tile__item__heading:before {
  height: 8.09375em;
}

@media (max-width: 1469px) {
  .floating-tile .floating-tile__item--bravo .floating-tile__item__heading:before {
    height: 18.625em;
  }
}

@media (max-width: 991px) {
  .floating-tile .container {
    padding-top: 7rem;
    padding-bottom: 4rem;
  }
  .floating-tile .floating-tile__item {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 1.15em;
  }
  .floating-tile .floating-tile__item .floating-tile__item__image {
    flex: 0 0 100%;
    order: 1;
    max-width: 100%;
    margin: 0 0 3rem;
  }
  .floating-tile .floating-tile__item .floating-tile__item__heading {
    flex: 0 0 100%;
    order: 2;
    max-width: 100%;
  }
  .floating-tile .floating-tile__item .floating-tile__item__heading:before {
    display: none;
    content: none;
  }
  .floating-tile .floating-tile__item .floating-tile__item__text {
    flex: 0 0 100%;
    order: 3;
    max-width: 100%;
  }
  .floating-tile .floating-tile__item .btn.btn-link {
    flex: 0 1 auto;
    order: 4;
  }
  .floating-tile .floating-tile__item--alpha .floating-tile__item__image {
    position: relative;
  }
}

@media (max-width: 991px) and (min-width: 576px) {
  .floating-tile .floating-tile__item--alpha .floating-tile__item__image:before {
    display: block;
    position: absolute;
    bottom: calc(100% + 1.125em);
    left: 0;
    width: 1px;
    height: 10rem;
    background-color: #FFFFFF;
    content: '';
  }
}

@media (max-width: 991px) {
  .floating-tile .floating-tile__item--alpha .floating-tile__item__image, .floating-tile .floating-tile__item--charlie .floating-tile__item__image {
    padding-bottom: 78%;
  }
  .floating-tile .floating-tile__item--bravo, .floating-tile .floating-tile__item--charlie {
    margin-top: 3rem;
  }
}

.floating-tile .scEnabledChrome .floating-tile__background {
  background-color: #000;
}

.floating-tile .scEnabledChrome .floating-tile__background img {
  position: absolute;
  height: 100%;
  object-fit: cover;
}

.floating-tile .content .mv-subheading-2 {
  padding-bottom: 0;
  font-size: 1.6em;
}
